import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import StyledSidebar from 'containers/sidebar/StyledSidebar';

const SideBar = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const handleMenuChange = (isOpen) => {
        setMenuOpen(isOpen)
    }

    return (
        <StyledSidebar id="Sidebar" {...props}>
            <Menu {...props} isOpen={menuOpen} onStateChange={({ isOpen }) => handleMenuChange(isOpen)}>
                <Link to="/" onClick={() => closeMenu()}>Hem</Link>
                <Link to="/usage" onClick={() => closeMenu()}>Energiåtgång</Link>
                <Link to="/heatcontrol" onClick={() => closeMenu()}>Styr din värme</Link>
                <Link to="/heatstats" onClick={() => closeMenu()}>Se din värmestatistik</Link>
                <Link to="/feedback" onClick={() => closeMenu()}>Ge feedback</Link>
            </Menu>
        </StyledSidebar>
    )
};

export default SideBar;