import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import {
    VictoryChart,
    VictoryTooltip,
    VictoryLine,
    VictoryZoomContainer,
    VictoryLegend
} from 'victory';

import StyledHeatStats from 'pages/heatStats/StyledHeatStats';

const HeatStats = ({ instructions, outcome }) => {

    const getData = line => {
        return _.map(line, point => {
            return { x: point.date, y: point.temperature }
        });
    };

    return (
        <StyledHeatStats>
            <h1>
                Din värmestatus
            </h1>
            <div className="heat-status-graph">
                <VictoryChart
                    containerComponent={<VictoryZoomContainer />}
                    scale={{ x: "time" }}
                >
                    <VictoryLegend x={100}
                        title=""
                        centerTitle
                        orientation="horizontal"
                        gutter={20}
                        style={{
                            border: { stroke: "black" },
                            title: { fontSize: 20 }
                        }}
                        data={[
                            { name: 'Planerad värme', symbol: { fill: "#aaa" } },
                            { name: 'Faktisk värme', symbol: { fill: "#cc00cc" } }
                        ]}
                    />

                    <VictoryLine
                        interpolation="natural"
                        labels={(d) => `${d.x.toLocaleString('sv-SE')}: ${d.y.toFixed(2)} C\xB0`}
                        labelComponent={<VictoryTooltip />}
                        data={getData(instructions)}
                        style={{ data: { stroke: '#aaa', strokeDasharray: '5,5' } }}
                    />

                    <VictoryLine
                        interpolation="natural"
                        labels={(d) => `${d.x.toLocaleString('sv-SE')}: ${d.y.toFixed(2)} C\xB0`}
                        labelComponent={<VictoryTooltip />}
                        data={getData(outcome)}
                        style={{ data: { stroke: '#c0c' } }}
                    />
                </VictoryChart>
            </div>
            <div className="info">
                <h2>Din månad</h2>
                <div className="saved">
                    <h3 className="title">Sparad energi</h3>
                    <span className="value">10%</span>
                </div>
                <div className="saved">
                    <h3 className="title">Sparat koldioxid</h3>
                    <span className="value">10%</span>
                </div>
                <div className="saved">
                    <h3 className="title">Sparade pengar</h3>
                    <span className="value">100 kr</span>
                </div>
            </div>
        </StyledHeatStats>
    );
};

const mapStateToProps = state => {
    const { heatStats: { instructions, outcome } } = state.data.data;
    return { instructions, outcome };
}

export default connect(
    mapStateToProps,
    {}
)(HeatStats);