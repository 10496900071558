import React, { useState } from 'react';

import StyledFeedbackInput from 'pages/feedback/feedbackInput/StyledFeedbackInput';
import FadeInFromSide from 'components/FadeInFromSide';

const FeedbackInput = ({ finished = false }) => {
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(finished);

    const submit = () => {
        setSubmitted(true);
    };

    return (
        <StyledFeedbackInput>
            {
                !submitted ?
                    <React.Fragment>
                        <textarea
                            name="FeedbackInput"
                            rows="5"
                            placeholder="Skriv din åsikt här"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}>
                        </textarea>
                        <button className="feedback-submit-btn" onClick={() => submit()}>Skicka</button>
                    </React.Fragment> :
                    <FadeInFromSide>
                        <h2>Tack för din feedback!</h2>
                    </FadeInFromSide>
            }
        </StyledFeedbackInput>
    );
};

export default FeedbackInput;