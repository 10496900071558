import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledHeatControl from 'pages/heatControl/StyledHeatControl';
import HeatControlCard from 'pages/heatControl/HeatControlCard';
import { setHeatControlActive } from "modules/data/actions";
import AddHeatControlButton from 'pages/heatControl/AddHeatControlButton';
import AddHeatControlModal from 'pages/heatControl/AddHeatControlModal';
import EditHeatControlModal from 'pages/heatControl/EditHeatControlModal';

const HeatControl = ({ heatControls, setActive, showAddHeatControlModal, showEditHeatControlModal, currentHeatControl }) => {

    return (
        <StyledHeatControl {...{ showAddHeatControlModal, showEditHeatControlModal }}>
            {showAddHeatControlModal && <AddHeatControlModal />}
            {showEditHeatControlModal && currentHeatControl && <EditHeatControlModal />}
            <AddHeatControlButton />
            {
                _.map(heatControls, control => {
                    return <HeatControlCard {...control} {...{ setActive, id: control.key }} />
                }).slice(0).reverse()
            }
        </StyledHeatControl>
    )
};

const mapStateToProps = state => {
    const { heatControls, showAddHeatControlModal, showEditHeatControlModal, currentHeatControl } = state.data.data
    return { heatControls, showAddHeatControlModal, showEditHeatControlModal, currentHeatControl }
};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key, active) => dispatch(setHeatControlActive(key, active))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeatControl);