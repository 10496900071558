import styled from 'styled-components';

const StyledFeedbackGeneral = styled.div`
    min-height: 35vh;

    .feedback-input {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .feedback-buttons {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .general-btn {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            border-width: 5px;
            font-weight: bold;
            font-size: 15pt;
            background-color: transparent;

            &.good {
                border-color: green;
            }

            &.bad {
                border-color: red;
            }
        }
    }
`

export default StyledFeedbackGeneral;