export const REPEAT_TYPE = {
    DAILY: 'repeatType/daily',
    WEEKLY: 'repeatType/weekly',
    MONTHLY: 'repeatType/monthly',
    YEARLY: 'repeatType/yearly',
    NO: 'repeatType/no'
};

export const HEAT_OPTION = {
    VERY_HIGH: 'heatOption/veryHigh',
    HIGH: 'heatOption/high',
    NORMAL: 'heatOption/normal',
    LOW: 'heatOption/low',
    VERY_LOW: 'heatOption/veryLow',
    NO_ONE_HOME: 'heatOption/noOneHome'
};

export const SET_ELECTRICITY_USAGE = 'SET_ELECTRICITY_USAGE';
export const SET_HEAT_CONTROL_ACTIVE = 'SET_HEAT_CONTROL_ACTIVE';
export const TOGGLE_ADD_HEAT_CONTROL_MODAL = 'TOGGLE_ADD_HEAT_CONTROL_MODAL';
export const SET_FOCUSED_DATE_FROM = 'SET_FOCUSED_DATE_FROM';
export const SET_FOCUSED_DATE_TO = 'SET_FOCUSED_DATE_TO';
export const ADD_HEAT_CONTROL = 'ADD_HEAT_CONTROL';
export const TOGGLE_EDIT_HEAT_CONTROL_MODAL = 'TOGGLE_EDIT_HEAT_CONTROL_MODAL';
export const SAVE_HEAT_CONTROL = 'SAVE_HEAT_CONTROL';