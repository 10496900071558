import styled from 'styled-components';

const StyledHeatControl = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    background-color: rgba(0, 0, 0, ${props => props.showAddHeatControlModal || props.showEditHeatControlModal ? 0.3 : 0});
`;

export default StyledHeatControl;