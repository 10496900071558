import styled from 'styled-components';

const StyledAddHeatControlModal = styled.div`
    position: absolute;
    top: ${props => props.yOffset ? props.yOffset + 20 : 20}px;
    width: 100%;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 4px 4px #ccc;

    .button-group {
        bottom: 0;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .btn {
        height: 60px;
        width: 60px;
        margin: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F9F9F9;

        &:focus {
            outline: none;
        }
    }

    .title {
        text-align: center;
        margin-top: 60px;
    }

    .name {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 1px #ddd;

        .name-title {
            
        }

        .name-input {
            height: 60px;
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
        }
    }

    .option {
        display: flex;
        flex-direction: column;

        select {
            padding: 10px;
            border: solid 1px #ddd;
            background-color: inherit;
            color: #6a6a6a;
            height: 60px;
        }
    }

    .date-picker {
        display: flex;
        flex-direction: column;
        position: relative;

        .react-datepicker-wrapper {
            width: 100%;
            height: 60px;
            border: solid 1px #ddd;

            .react-datepicker__input-container {
                width: 100%;
                height: 100%;

                .react-datepicker-input {
                    width: 100%;
                    height: 100%;
                    padding: 0 10px;
                    box-sizing: border-box;
                }
            }
        }

        .react-datepicker-popper {
            left: 50% !important;
            margin-left: -156px;
        }
    }
`;

export default StyledAddHeatControlModal;