import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import BarChart from 'components/BarChart';
import StyledUsage from 'pages/usage/StyledUsage';
import ContainerLink from 'components/ContainerLink';
import GroupFadeInFromSide from 'components/GroupFadeInFromSide';

const Usage = ({ usage, normUser }) => {
    const [totalElectricity] = useState(getTotalUsage(usage.electricity));
    const [totalWater] = useState(getTotalUsage(usage.water));
    const [totalHeat] = useState(getTotalUsage(usage.heat));
    const [normElectricity] = useState(getTotalUsage(normUser.electricity));
    const [normWater] = useState(getTotalUsage(normUser.water));
    const [normHeat] = useState(getTotalUsage(normUser.heat));

    function getTotalUsage(rawUsage, days = 30) {
        return _.reduce(rawUsage.slice(-days), (previous, day) => previous + day.y, 0);
    }

    return (
        <StyledUsage>
            <GroupFadeInFromSide>
                <h1>
                    Din energiåtgång
                </h1>
                <ContainerLink to="/usage/details/electricity">
                    <BarChart
                        bars={[
                            { value: totalElectricity, color: 'green', name: 'Du' },
                            { value: normElectricity, color: 'purple', name: 'Normen i området' }
                        ]}
                        title="Elförbrukning"
                    />
                </ContainerLink>

                <ContainerLink to="/usage/details/water">
                    <BarChart
                        bars={[
                            { value: totalWater, color: 'green', name: 'Du' },
                            { value: normWater, color: 'blue', name: 'Normen i området' }
                        ]}
                        title="Vattenförbrukning"
                    />
                </ContainerLink>

                <ContainerLink to="/usage/details/heat">
                    <BarChart
                        bars={[
                            { value: totalHeat, color: 'green', name: 'Du' },
                            { value: normHeat, color: 'orange', name: 'Normen i området' }
                        ]}
                        title="Värmeförbrukning"
                    />
                </ContainerLink>
            </GroupFadeInFromSide>
        </StyledUsage>
    );
};

const mapStateToProps = (state) => {
    const { usage, normUser } = state.data.data

    return { usage, normUser };
}

export default connect(
    mapStateToProps,
    {}
)(Usage);