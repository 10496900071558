import React from 'react';
import { Link } from 'react-router-dom';

import StyledContainerLink from 'components/containerLink/StyledContainerLink';

const ContainerLink = ({ children, to }) => {
    return (
        <StyledContainerLink>
            {children}
            <Link to={to} className="absolute-fill" />
        </StyledContainerLink>
    );
};

export default ContainerLink;