import styled from 'styled-components';

const StyledUsage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .feedback-submit-btn {
        width: 90%;
        max-width: 300px;
        background-color: green;
        height: 60px;
        border-radius: 30px;
        color: white;
        font-size: 15pt;
        font-weight: bold;
    }

    textarea {
        border: 1px solid lightgrey;
        border-radius: 5px;
        flex-grow: 1;
        margin-bottom: 20px;
        width: 100%;
    }
`

export default StyledUsage;