import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import StyledHome from 'pages/home/StyledHome';
import Mood from 'components/Mood';
import FadeInFromSide from 'components/FadeInFromSide';

const moodStrings = {
    good: 'Var stolt, för din energiförbrukning är bra!',
    neutral: 'Ingen fara än, men ta det lugnt med användandet!',
    bad: 'Se över dina energivanor; det här är inte hållbart!'
}

const Home = ({ userGoals, usage }) => {
    const generateMood = days => {
        const totalUsage = getTotalUsage(days);
        const totalGoal = userGoals * days;

        if (totalUsage <= totalGoal) {
            return 'good';
        }
        if (totalUsage <= totalGoal * 1.6) {
            return 'neutral';
        }
        return 'bad';
    };

    const getTotalUsage = days => _.reduce(usage, (previous, type) => (
        previous + _.reduce(type.slice(-days), (previous, day) => previous + day.y, 0)
    ), 0);

    const mood = generateMood(30);

    return (
        <FadeInFromSide>
            <StyledHome>
                <div className="rating">
                    <Mood mood={mood} size="10x" />
                    <h3>{moodStrings[mood]}</h3>
                </div>
                <FadeInFromSide>
                    <div className="feedback-prompt">
                        <h3>Är du missnöjd med din fastighet?</h3>
                        <button id="feedback-btn" >
                            <Link to="/feedback" style={{ display: 'inline-block', height: '100%', width: '100%' }}>GE DIN ÅSIKT</Link>
                        </button>
                    </div>
                </FadeInFromSide>
            </StyledHome>
        </FadeInFromSide>
    )
};

const mapStateToProps = (state) => {
    const { userGoals, usage } = state.data.data

    return { userGoals, usage };
}

export default connect(
    mapStateToProps,
    {}
)(Home);