import React, { useState } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqid from 'uniqid';
import DatePicker from 'react-datepicker';
import sv from 'date-fns/locale/sv';

import 'react-datepicker/dist/react-datepicker.css';

import StyledHeatControlModal from 'pages/heatControl/heatControlModal/StyledHeatControlModal';
import { toggleEditHeatControlModalAction, saveHeatControlAction } from 'modules/data/actions';
import { HEAT_OPTION, REPEAT_TYPE } from 'modules/data/types';

function translateRepeat(repeat) {
    switch (repeat) {
        case REPEAT_TYPE.DAILY:
            return 'varje dag';
        case REPEAT_TYPE.WEEKLY:
            return 'varje vecka';
        case REPEAT_TYPE.MONTHLY:
            return 'varje månad';
        case REPEAT_TYPE.YEARLY:
            return 'varje år';
        case REPEAT_TYPE.NO:
            return 'inte';
        default:
            return null;
    }
}

function translateOption(option) {
    switch (option) {
        case HEAT_OPTION.VERY_HIGH:
            return 'extra hög';
        case HEAT_OPTION.HIGH:
            return 'hög';
        case HEAT_OPTION.NORMAL:
            return 'normal';
        case HEAT_OPTION.LOW:
            return 'låg';
        case HEAT_OPTION.VERY_LOW:
            return 'extra låg';
        case HEAT_OPTION.NO_ONE_HOME:
            return 'bortrest'
        default:
            return null;
    }
}

const heatOptions = _.map(HEAT_OPTION, option => {
    return { value: option, label: translateOption(option) }
})

const repeatOptions = _.map(REPEAT_TYPE, option => {
    return { value: option, label: translateRepeat(option) }
})

const AddHeatControlModal = ({ toggleModal, saveHeatControl, heatControls, currentHeatControl }) => {
    const [heatOption, setHeatOption] = useState(heatControls[currentHeatControl].option);
    const [repeatOption, setRepeatOption] = useState(heatControls[currentHeatControl].repeat);
    const [name, setName] = useState(heatControls[currentHeatControl].name);
    const [startDate, setStartDate] = useState(heatControls[currentHeatControl].time.start);
    const [endDate, setEndDate] = useState(
        new Date(heatControls[currentHeatControl].time.start.getTime() + heatControls[currentHeatControl].time.duration)
    );

    const onHeatSelect = (event) => {
        setHeatOption(event.target.value)
    }

    const onRepeatSelect = (option) => {
        setRepeatOption(option.target.value)
    }

    const handleStartDateChange = date => {
        setStartDate(date)
        if (date.getTime() > endDate.getTime()) {
            setEndDate(new Date(date.getTime() + 1000 * 60 * 60));
        }
    }

    const handleEndDateChange = date => {
        setEndDate(date)
        if (date.getTime() < startDate.getTime()) {
            setStartDate(new Date(date.getTime() - 1000 * 60 * 60));
        }
    }

    const submitHeatControl = () => {
        let start = startDate ? startDate : new Date();
        let end = new Date(start);
        end.setDate(end.getDate() + 1);
        end = endDate ? endDate : end;

        saveHeatControl(currentHeatControl, name, start, end, heatOption, repeatOption)
        toggleModal()
    }

    return (
        <StyledHeatControlModal yOffset={window.pageYOffset}>
            <div className="title">
                <h2>Ändra värmekontroll</h2>
            </div>
            <div className="name">
                <span className="name-title"></span>
                <input type="text" className="name-input" value={name} placeholder="Ange namn..." onChange={e => setName(e.target.value)} />
            </div>
            <div className="date-picker">
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    locale={sv}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    customInput={<DatePickerButton prefix="Starttid:" />}
                    placeholderText="Välj starttid..."
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeCaption="tid"
                />
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    locale={sv}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    customInput={<DatePickerButton prefix="Sluttid:" />}
                    placeholderText="Välj sluttid..."
                    dateFormat="yyyy-MM-dd HH:mm"
                    timeCaption="tid"
                />
            </div>
            <div className="option">
                <select required name="heat" className="heat" value={heatOption} onChange={e => onHeatSelect(e)}>
                    <option value="" disabled hidden>Välj värmeinställning</option>
                    {
                        _.map(heatOptions, option => <option key={uniqid()} value={option.value}>{option.label} värme</option>)
                    }
                </select>
                <select required name="repeat" className="repeat" value={repeatOption} onChange={e => onRepeatSelect(e)}>
                    <option value="" disabled hidden>Välj repetition</option>
                    {
                        _.map(repeatOptions, option => <option key={uniqid()} value={option.value}>{option.label}</option>)
                    }
                </select>
            </div>

            <div className="button-group">
                <button className="submit btn" onClick={submitHeatControl} >
                    <FontAwesomeIcon icon={['fas', 'check']} size='2x' />
                </button>
                <button className="exit btn" onClick={toggleModal} >
                    <FontAwesomeIcon icon={['fas', 'times']} size='2x' />
                </button>
            </div>
        </StyledHeatControlModal>
    );
};

class DatePickerButton extends React.Component {

    render() {
        return (
            <input
                className="react-datepicker-input"
                readOnly
                onClick={this.props.onClick}
                value={this.props.prefix + ' ' + this.props.value}
                placeholder={this.props.placeholder}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: () => dispatch(toggleEditHeatControlModalAction()),
        saveHeatControl: (key, name, start, end, heatOption, repeatOption) => dispatch(saveHeatControlAction(key, name, start, end, heatOption, repeatOption))
    };
};

const mapStateToProps = state => {
    const { currentHeatControl, heatControls } = state.data.data;
    return { heatControls, currentHeatControl };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddHeatControlModal);