import {
    SET_HEAT_CONTROL_ACTIVE,
    TOGGLE_ADD_HEAT_CONTROL_MODAL,
    SET_FOCUSED_DATE_FROM,
    SET_FOCUSED_DATE_TO,
    ADD_HEAT_CONTROL,
    TOGGLE_EDIT_HEAT_CONTROL_MODAL,
    SAVE_HEAT_CONTROL
} from 'modules/data/types';

export const setHeatControlActive = (key, active) => ({
    type: SET_HEAT_CONTROL_ACTIVE,
    active, key
});

export const toggleAddHeatControlModal = () => ({
    type: TOGGLE_ADD_HEAT_CONTROL_MODAL
});

export const setFocusedDateFrom = from => ({
    type: SET_FOCUSED_DATE_FROM,
    from
});

export const setFocusedDateTo = to => ({
    type: SET_FOCUSED_DATE_TO,
    to
});

export const addHeatControlAction = (name, start, end, heatOption, repeatOption) => ({
    type: ADD_HEAT_CONTROL,
    name, start, end, heatOption, repeatOption
});

export const toggleEditHeatControlModalAction = (key) => ({
    type: TOGGLE_EDIT_HEAT_CONTROL_MODAL,
    key
});

export const saveHeatControlAction = (key, name, start, end, heatOption, repeatOption) => ({
    type: SAVE_HEAT_CONTROL,
    key, name, start, end, heatOption, repeatOption
});