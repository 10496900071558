import styled from 'styled-components';

const StyledAddHeatControl = styled.button`
    height: 60px;
    width: 60px;
    margin: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;

    &:focus {
        outline: none;
    }
`;

export default StyledAddHeatControl;