import styled from 'styled-components';

const StyledSidebar = styled.div`
    .bm-item {
        display: inline-block;

        text-decoration: none;
        margin-bottom: 10px;
        color: #d1d1d1;
        transition: color 0.2s;
    }

    @media screen and (pointer: fine){
        .bm-item:hover {
            color: white;
        }
    }

    .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        left: 10px;
        top: 10px;
    }

    .bm-burger-bars {
        background: #373a47;
    }

    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    .bm-cross {
        background: #bdc3c7;
    }

    .bm-menu {
        background: #373a47;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    .bm-morph-shape {
        fill: #373a47;
    }

    .bm-item-list {
        color: #b8b7ad;
    }

    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`

export default StyledSidebar;