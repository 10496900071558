import { combineReducers } from 'redux';
import _ from 'underscore';
import uniqid from 'uniqid';

import {
    REPEAT_TYPE,
    HEAT_OPTION,
    SET_HEAT_CONTROL_ACTIVE,
    SET_ELECTRICITY_USAGE,
    TOGGLE_ADD_HEAT_CONTROL_MODAL,
    SET_FOCUSED_DATE_FROM,
    SET_FOCUSED_DATE_TO,
    ADD_HEAT_CONTROL,
    TOGGLE_EDIT_HEAT_CONTROL_MODAL,
    SAVE_HEAT_CONTROL
} from 'modules/data/types';

function generateUsageData(points, min = 0, max = 300, step = max / 10) {
    if (max < min) {
        let tmp = max
        max = min
        min = tmp
    }
    let result = []
    let last = Math.random() * (max - min) + min
    for (let i = 0; i < points; i++) {
        let generated = Math.random() * step
        let current = last + generated - step / 2
        current = current < min ? min : current > max ? max : current

        let date = new Date();
        date.setDate(date.getDate() - points + i)
        result.push({
            x: date,
            y: current
        })
        last = current
    }
    return result
}

function generateInstructions(days) {
    return _.map(_.range(days), (i) => {
        let date = new Date();
        date.setDate(date.getDate() + i);

        return {
            temperature: 17 + Math.round(Math.random() * 6),
            date
        }
    });
}

const instructions = generateInstructions(30)

function generateOutcome(instructions) {
    return _.map(instructions, (instruction) => {
        const temperature = instruction.temperature + Math.random() * 6 - 3;
        return {
            temperature,
            date: instruction.date
        }
    });
}

const heatControlKeys = _.map(_.range(6), () => uniqid());

const heatControls = _.reduce(heatControlKeys, (result, key) => {
    result[key] = {
        key,
        name: key,
        time: {
            start: new Date(2019, 2, 10),
            duration: 1000 * 60 * 60 * 24 * 14
        },
        repeat: REPEAT_TYPE.YEARLY,
        option: HEAT_OPTION.NO_ONE_HOME,
        active: true
    };

    return result;
}, {})

const initialState = {
    usage: {
        electricity: generateUsageData(365),
        water: generateUsageData(365),
        heat: generateUsageData(365)
    },
    userGoals: 333,
    co2: {
        current: Math.random() * 10000 / 12,
        previous: Math.random() * 10000 / 12,
        goal: 7000
    },
    normUser: {
        electricity: generateUsageData(365),
        water: generateUsageData(365),
        heat: generateUsageData(365),
        co2: Math.random() * 10000 / 12
    },
    heatControls,
    currentHeatControl: undefined,
    showAddHeatControlModal: false,
    showEditHeatControlModal: false,
    focusedDate: {
        from: undefined,
        to: undefined
    },
    heatStats: {
        instructions,
        outcome: generateOutcome(instructions)
    }
}

const data = (state = initialState, action) => {
    switch (action.type) {
        case SET_ELECTRICITY_USAGE:
            return {
                ...state,
                usage: {
                    electricity: {
                        date: action.date,
                        value: action.value
                    }
                }
            };
        case SET_HEAT_CONTROL_ACTIVE:
            return {
                ...state,
                heatControls: {
                    ...state.heatControls,
                    [action.key]: {
                        ...state.heatControls[action.key],
                        active: action.active
                    }
                }
            }
        case TOGGLE_ADD_HEAT_CONTROL_MODAL:
            return {
                ...state,
                showAddHeatControlModal: !state.showAddHeatControlModal
            }
        case TOGGLE_EDIT_HEAT_CONTROL_MODAL:
            return {
                ...state,
                showEditHeatControlModal: !state.showEditHeatControlModal,
                currentHeatControl: action.key
            }
        case SET_FOCUSED_DATE_FROM:
            return {
                ...state,
                focusedDate: {
                    ...state.focusedDate,
                    from: action.from
                }
            }
        case SET_FOCUSED_DATE_TO:
            return {
                ...state,
                focusedDate: {
                    ...state.focusedDate,
                    to: action.to
                }
            }
        case ADD_HEAT_CONTROL:
            let key = uniqid();
            return {
                ...state,
                heatControls: {
                    ...state.heatControls,
                    [key]: {
                        key,
                        name: action.name,
                        time: {
                            start: action.start,
                            duration: action.end.getTime() - action.start.getTime()
                        },
                        repeat: action.repeatOption,
                        option: action.heatOption,
                        active: true
                    }
                }
            }
        case SAVE_HEAT_CONTROL:
            return {
                ...state,
                heatControls: {
                    ...state.heatControls,
                    [action.key]: {
                        key: action.key,
                        name: action.name,
                        time: {
                            start: action.start,
                            duration: action.end.getTime() - action.start.getTime()
                        },
                        repeat: action.repeatOption,
                        option: action.heatOption,
                        active: true
                    }
                }
            }
        default:
            return state;
    }
}

export default combineReducers({
    data
})