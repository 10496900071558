import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledDetails from 'pages/details/StyledDetails';
import LineChart from 'components/LineChart';
import GroupFadeInFromSide from 'components/GroupFadeInFromSide';

const Details = ({ match: { params: { graphType } }, co2, usage: { electricity, heat, water } }) => {
    const [totalCo2] = useState(co2.current * (new Date().getMonth() + 1))

    const graph = function (graphType) {
        switch (graphType) {
            case 'electricity':
                return (
                    <LineChart
                        lines={[{ values: electricity, color: 'purple', key: 'ElectricityLine' }]}
                        title="Elförbrukning"
                        yUnit="kWh"
                    />
                );
            case 'water':
                return (
                    <LineChart
                        lines={[{ values: water, color: 'blue', key: 'WaterLine' }]}
                        title="Vattenförbrukning"
                        yUnit="Vattenenheter"
                    />
                );
            case 'heat':
                return (
                    <LineChart
                        lines={[{ values: heat, color: 'orange', key: 'HeatLine' }]}
                        title="Värmeförbrukning"
                        yUnit="Värmeenheter"
                    />
                );
            default:
                return null
        }
    }(graphType)

    return (
        <StyledDetails>
            <GroupFadeInFromSide>
                <h1 className="title">
                    <a href="https://www.klimatkalkylatorn.se/" rel="noopener noreferrer" target="_blank">Klimatkalkylatorn.se</a>
                </h1>

                {graph}

                <div className="info">
                    <div className="co2">
                        <h2>{Math.round(totalCo2)} kg CO<sub>2</sub></h2>
                    </div>
                    <div className="trend">
                        <h3>Trend:</h3>
                        {
                            co2.current < co2.previous - co2.previous * 0.1 ?
                                <FontAwesomeIcon icon={['fas', 'long-arrow-alt-down']} style={{ color: 'green' }} size="3x" /> :
                                co2.current > co2.previous + co2.previous * 0.1 ?
                                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-up']} style={{ color: 'red' }} size="3x" /> :
                                    <FontAwesomeIcon icon={['fas', 'long-arrow-alt-right']} style={{ color: 'orange' }} size="3x" />
                        }
                    </div>
                    <div className="goal">
                        <h3>Mål för {new Date().getFullYear()}:</h3>
                        <h3>{Math.round(100 * (totalCo2 / co2.goal))}%</h3>
                    </div>
                </div>
            </GroupFadeInFromSide>
        </StyledDetails>
    );
};

const mapStateToProps = (state) => {
    const { co2, usage } = state.data.data;
    return { co2, usage };
};

export default connect(
    mapStateToProps,
    {}
)(Details)