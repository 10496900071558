import styled from 'styled-components';

const StyledDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;

    min-height: 100vh;

    .title {
        a {
            color: inherit;
            text-decoration: none;
            border-radius: 10px;
            background-color: lightgreen;
            padding: 10px;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .co2 {
            border: 5px solid red;
            border-radius: 3px;
            padding: 20px;
            min-width: 200px;
            text-align: center;
        }

        .trend {
            margin-top: 20px;
            display: flex;
            align-items: center;
            
            h3 {
                margin-right: 20px;
            }
        }

        .goal {
            display: flex;
            
            h3:first-child {
                margin-right: 20px;
            }
        }
    }
`

export default StyledDetails;