import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledAddHeatControlButton from 'pages/heatControl/addHeatControl/StyledAddHeatControlButton';
import { toggleAddHeatControlModal } from 'modules/data/actions';

const AddHeatControlButton = ({ toggleModal }) => {

    return (
        <StyledAddHeatControlButton onClick={toggleModal}>
            <FontAwesomeIcon icon={['fas', 'plus']} size='2x' />
        </StyledAddHeatControlButton>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        toggleModal: () => dispatch(toggleAddHeatControlModal())
    }
}

export default connect(
    null,
    mapDispatchToProps
)(AddHeatControlButton);