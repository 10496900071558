import styled from 'styled-components';

const StyledContainerLink = styled.div`
    position: relative;

    .absolute-fill {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;

export default StyledContainerLink;