import React from 'react';
import { Animate } from 'react-simple-animate';

const FadeInFromSide = ({ children }) => (
    <Animate
        startStyle={{ opacity: 0, transform: 'translateX(-10px)' }}
        durationSeconds={0.5}
        play={true}
        easeType='cubic-bezier(0.19, 1, 0.22, 1)'
    >
        {children}
    </Animate>
);

export default FadeInFromSide;