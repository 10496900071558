import styled from 'styled-components';

const StyledServiceFeedback = styled.div`
    &.electricity {
        color: purple;
    }

    &.water {
        color: blue;
    }

    &.heat {
        color: orange;
    }

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .title {
        min-width: 100px;
        width: 50%;
        margin-left: 20px;
    }

    .detail-btn {
        font-size: 20pt;
        font-weight: bold;
        color: inherit;
        background-color: transparent;
        flex-grow: 0;
        border-radius: 25%;
        min-width: 2em;
        border: none;
    }

    .choice {
        font-size: 20pt;
        font-weight: bold;
        margin-right: 2em;
    }
`;

export default StyledServiceFeedback;