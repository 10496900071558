import React from 'react';
import { Provider } from 'react-redux'
import GlobalStyle from 'GlobalStyle'

import App from 'containers/App'

const Root = ({ store }) => (
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>
);

export default Root;
