import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSmile, faMeh, faFrown, faLongArrowAltDown, faLongArrowAltUp, faLongArrowAltRight, faPlus, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

import Sidebar from 'containers/Sidebar';
import Main from 'containers/Main';

library.add(faSmile, faMeh, faFrown, faLongArrowAltDown, faLongArrowAltUp, faLongArrowAltRight, faPlus, faTimes, faCheck);

const App = () => (
    <Router>
        <div id="App">
            <Sidebar pageWrapId={"page-wrap"} outerContainerId={"App"} />

            <Main id="page-wrap" />
        </div>
    </Router>
);

export default App;