import React, { useState } from 'react';

import FeedbackInput from 'pages/feedback/FeedbackInput';
import StyledFeedbackGeneral from 'pages/feedback/feedbackGeneral/StyledFeedbackGeneral.js';

import FadeInFromSide from 'components/FadeInFromSide';

const FeedbackGeneral = () => {
    const [showInput, setShowInput] = useState(false);
    const [feedbackInputFinished, setFeedbackInputFinished] = useState(false)

    const displayFinishedInput = () => {
        setShowInput(true);
        setFeedbackInputFinished(true);
    }

    return (
        <StyledFeedbackGeneral>
            {
                showInput ?
                    <FadeInFromSide>
                        <div className="feedback-input"><FeedbackInput finished={feedbackInputFinished} /></div>
                    </FadeInFromSide> :
                    <div className="feedback-buttons">
                        <h1 className="tooltip">Är du nöjd?</h1>
                        <button className="general-btn good" onClick={() => displayFinishedInput()}><h3>Ja</h3></button>
                        <button className="general-btn bad" onClick={() => setShowInput(true)}><h3>Nej</h3></button>
                    </div>
            }
        </StyledFeedbackGeneral>
    );
};

export default FeedbackGeneral;