import React from 'react';
import _ from 'underscore';

import StyledFeedback from 'pages/feedback/StyledFeedback';
import ServiceFeedback from 'pages/feedback/ServiceFeedback';
import FeedbackGeneral from 'pages/feedback/FeedbackGeneral';
import GroupFadeInFromSide from 'components/GroupFadeInFromSide';

const Feedback = () => {
    return (
        <StyledFeedback>
            <GroupFadeInFromSide>
                <FeedbackGeneral />
                <div className="feedback-detail">
                    <h2 className="tooltip">Åsikt på tjänster</h2>
                    <GroupFadeInFromSide>
                        {
                            _.map([
                                { type: 'electricity', title: 'El' },
                                { type: 'water', title: 'Vatten' },
                                { type: 'heat', title: 'Värme' }], (props) => (
                                    <ServiceFeedback {...props} key={'service-' + props.type} />
                                ))
                        }
                    </GroupFadeInFromSide>
                </div>
            </GroupFadeInFromSide>
        </StyledFeedback>
    );
};

export default Feedback;