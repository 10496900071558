import styled from 'styled-components';

const StyledHeatControlCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0px 0px 1px 0px #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .name {
            font-size: 16pt;
            font-weight: bold;
            font-style: italic;
            max-width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 3px;
            box-sizing: border-box;
        }

        .active {
            
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;

        .info-title {
            font-weight: bold;
        }

        .start {
            margin-top: 20px;
            flex-basis: 100%;
        }

        .option {
            
        }

        .repeat {

        }
    }
`;

export default StyledHeatControlCard;