import styled from 'styled-components';

const StyledHome = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 20px;
    height: 100vh;

    div {
        text-align: center;
    }

    .feedback-prompt {
        display: flex;
        flex-direction: column;
        align-items: center;
        

        #feedback-btn {
            width: 90%;
            max-width: 300px;
            background-color: green;
            height: 60px;
            border-radius: 30px;

            display: flex;
            align-items: center;
            justify-content: center;

            a {
                color: white;
                text-decoration: none;
                font-weight: bold;
                font-size: 15pt;
            }
        }
    }
`;

export default StyledHome;