import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body, html {
        margin: 0;
    }
    #App {
        font-family: sans-serif;
    }
`

export default GlobalStyle;