import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from 'modules/rootReducer'
import rootSaga from 'modules/rootSaga'

const sagaMiddleware = createSagaMiddleware()

const store = () => {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const middlewares = [sagaMiddleware]

    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middlewares))
    )

    sagaMiddleware.run(rootSaga)

    return store
}

export default store