import React, { useState } from 'react';
import {
    VictoryChart,
    VictoryLine,
    VictoryTooltip,
    VictoryLegend
} from 'victory';
import { VictoryVoronoiContainer } from 'victory-voronoi-container';
import _ from 'underscore';


const LineChart = ({ lines, max, title, xUnit, yUnit, days }) => {
    const [today] = useState(new Date());
    const [startDay] = days ? useState(new Date().setDate(today.getDate() - days)) : useState(getFirstDay())

    function getFirstDay() {
        return lines.reduce((previous, line) => {
            return line.values[0].x < previous ? line.values[0].x : previous
        }, today)
    }

    const getData = (values) => {
        const maxPoints = 31;
        const xDomain = [startDay, today];

        values = values.filter(
            (d) => (d.x >= xDomain[0] && d.x <= xDomain[1]));

        if (values && values.length > maxPoints) {
            const k = Math.ceil(values.length / maxPoints);
            return values.filter(
                (d, i) => ((i % k) === 0)
            );
        }

        return values;
    }

    return (
        <div className="line-chart">
            <VictoryChart
                scale={{ x: 'time' }}
                maxDomain={{ y: max, x: today }}
                minDomain={{ y: 0, x: startDay }}
                containerComponent={
                    <VictoryVoronoiContainer />
                }
            >
                <VictoryLegend x={125} y={20} title={title} centerTitle data={[]}
                    style={{ title: { fontSize: 20 } }}
                />
                {
                    _.map(lines, line => (
                        <VictoryLine
                            key={line.key}
                            interpolation="natural"
                            labels={(d) => `${d.x.toDateString()}: ${d.y.toFixed(2)} ${yUnit}`}
                            labelComponent={<VictoryTooltip />}
                            data={getData(line.values)}
                            style={{ data: { stroke: line.color } }}
                        />
                    ))
                }
            </VictoryChart>
        </div>
    );
};

export default LineChart;