import React from 'react';
import { Route } from 'react-router-dom';

import Home from 'pages/Home';
import Usage from 'pages/Usage';
import Feedback from 'pages/Feedback';
import Details from 'pages/Details';
import HeatControl from 'pages/HeatControl';
import HeatStats from 'pages/HeatStats';

const Main = ({ id }) => (
    <main id={id}>
        <Route exact path="/" component={Home} />
        <Route exact path="/usage" component={Usage} />
        <Route path="/usage/details/:graphType" component={Details} />
        <Route exact path="/heatcontrol" component={HeatControl} />
        <Route exact path="/heatstats" component={HeatStats} />
        <Route path="/feedback" component={Feedback} />
    </main>
);

export default Main;