import React, { useState } from 'react';

import StyledServiceFeedback from 'pages/feedback/serviceFeedback/StyledServiceFeedback';
import FadeInFromSide from 'components/FadeInFromSide';

const ServiceFeedback = ({ type, title }) => {
    const [submitted, setSubmitted] = useState({
        electricity: false, water: false, heat: false
    });
    const [feedback, setFeedback] = useState({});

    const handleSubmit = (e) => {
        const [submitType, submitValue] = e.target.value.split(',')
        setSubmitted({ ...submitted, [submitType]: true });
        setFeedback({ ...feedback, [submitType]: submitValue });
    };

    return (
        <StyledServiceFeedback className={"detail " + type}>
            <h2 className="title">{title}</h2>
            {!submitted[type] ?
                <React.Fragment>
                    <button className="detail-btn good" value={[type, "good"]} onClick={(e) => handleSubmit(e)} >+</button>
                    <button className="detail-btn bad" value={[type, "bad"]} onClick={(e) => handleSubmit(e)} > -</button>
                </React.Fragment> :
                <FadeInFromSide>
                    {
                        feedback[type] === 'good' ?
                            <div className="choice">+</div> :
                            <div className="choice">-</div>
                    }
                </FadeInFromSide>
            }
        </StyledServiceFeedback>
    )
}

export default ServiceFeedback;