import styled from 'styled-components';

const StyledFeedback = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 20px 0 20px;

    min-height: 100vh;

    .tooltip {
        width: 100%;
        text-align: center;
    }
`

export default StyledFeedback;