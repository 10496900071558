import styled from 'styled-components';

const StyledHome = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    padding: 0 20px;
    height: 100vh;

    .info {
        text-align: center;
        width: 100%;

        .saved {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }
`;

export default StyledHome;