import React from 'react';
import Switch from 'react-switch';
import { connect } from 'react-redux';

import StyledHeatControlCard from 'pages/heatControl/heatControlCard/StyledHeatControlCard';
import { REPEAT_TYPE, HEAT_OPTION } from "modules/data/types";
import { toggleEditHeatControlModalAction } from 'modules/data/actions';

function translateRepeat(repeat) {
    switch (repeat) {
        case REPEAT_TYPE.DAILY:
            return 'varje dag';
        case REPEAT_TYPE.WEEKLY:
            return 'varje vecka';
        case REPEAT_TYPE.MONTHLY:
            return 'varje månad';
        case REPEAT_TYPE.YEARLY:
            return 'varje år';
        case REPEAT_TYPE.NO:
            return 'inte';
        default:
            return null;
    }
}

function translateOption(option) {
    switch (option) {
        case HEAT_OPTION.VERY_HIGH:
            return 'extra hög';
        case HEAT_OPTION.HIGH:
            return 'hög';
        case HEAT_OPTION.NORMAL:
            return 'normal';
        case HEAT_OPTION.LOW:
            return 'låg';
        case HEAT_OPTION.VERY_LOW:
            return 'extra låg';
        case HEAT_OPTION.NO_ONE_HOME:
            return 'bortrest'
        default:
            return null;
    }
}

const HeatControlCard = ({ name, time: { start, duration }, repeat, option, active, setActive, id, toggleEditHeatControlModal }) => {

    return (
        <StyledHeatControlCard>
            <div className="title">
                <div className="name">{name}</div>
                <Switch className="active" onChange={(checked) => setActive(id, checked)} checked={active} />
            </div>
            <div className="info" onClick={() => toggleEditHeatControlModal(id)}>
                <span className="option"><span className="info-title">Inställning:</span> {translateOption(option)}</span>
                <span className="repeat"><span className="info-title">Repeteras:</span> {translateRepeat(repeat)}</span>
                <span className="start">{start.toLocaleString('sv-SE')}</span>
            </div>
        </StyledHeatControlCard>
    );
};

const mapDispatchToProps = dispatch => ({
    toggleEditHeatControlModal: (key) => dispatch(toggleEditHeatControlModalAction(key))
});

export default connect(
    null,
    mapDispatchToProps
)(HeatControlCard);