import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Mood = ({ mood, size }) => {

    const config = function (mood) {
        if (mood === 'good') {
            return { icon: ['fas', 'smile'], color: 'green' };
        }
        if (mood === 'neutral') {
            return { icon: ['fas', 'meh'], color: 'orange' };
        }
        if (mood === 'bad') {
            return { icon: ['fas', 'frown'], color: 'red' };
        }
        return null;
    }(mood);

    return (
        <FontAwesomeIcon {...config} size={size} />
    );
};

export default Mood;