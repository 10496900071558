import React, { useState } from 'react';
import {
    VictoryChart,
    VictoryBar,
    VictoryTooltip,
    VictoryLegend
} from 'victory';
import _ from 'underscore';


const BarChart = ({ bars, title, yUnit }) => {
    const [maxValue] = useState(getMaxValue())

    function getMaxValue() {
        return Math.max.apply(Math, _.map(bars, bar => bar.value));
    }

    return (
        <VictoryChart
            domainPadding={{ x: 100 }}
        >
            <VictoryLegend x={125} y={20} title={title} centerTitle data={[]}
                style={{ title: { fontSize: 20 } }}
            />
            {
                <VictoryBar
                    labels={(d) => `${d.x}: ${d.value.toFixed(2)} ${yUnit}`}
                    labelComponent={<VictoryTooltip />}
                    data={bars}
                    style={{ data: { fill: d => d.value === maxValue && !d.name.toLowerCase().includes('norm') ? 'red' : d.color } }}
                    x="name"
                    y={d => d.value}
                />
            }
        </VictoryChart>
    );
};

export default BarChart;